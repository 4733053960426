import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Episode } from 'app/modules/title/models/episode.model';

@Component({
  selector: 'bolt-autopopulate-metadata-item',
  template: require('./bolt-autopopulate-metadata-item.html'),
  styles: [require('./bolt-autopopulate-metadata-item.scss')]
})
export class BoltAutopopulateItemComponent {
  @Input() active: boolean;
  @Input() episode: Episode;
  @Output() selected = new EventEmitter<Episode>();

  lockingStatusSubscription: any;

  constructor() { }

  /**
   * Sets the given title as the current selection.
   *
   * @returns void
   */
  notifySelection(): void {
    this.selected.emit(this.episode);
  }
}
