import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from 'primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BoltCommonModule } from '../common/common.module';
import { SharedModule } from 'app/shared/shared.module';
import { MasterDataManager } from './services/manager/manager';
import { MasterDataService } from './services/master-data.service';

import { BoltMasterDataLanguageHandlerPanelComponent } from './components/language/bolt-master-data-language-handler-panel/bolt-master-data-language-handler-panel.component';
import { BoltMasterDataFiltersComponent } from './components/common/bolt-master-data-filter/bolt-master-data-filter.component';
import { BoltMasterDataLanguageComponent } from './components/language/bolt-master-data-language/bolt-master-data-language.component';
import { BoltMasterDataTerritoryComponent } from './components/territory/bolt-master-data-territory/bolt-master-data-territory.component';
import { BoltMasterDataTerritoryHandlerPanelComponent } from './components/territory/bolt-master-data-territory-handler-panel/bolt-master-data-territory-handler-panel.component';
import { BoltMasterDataAccountComponent } from './components/account/bolt-master-data-account/bolt-master-data-account.component';
import { BoltMasterDataAccountHandlerPanelComponent } from './components/account/bolt-master-data-account-handler-panel/bolt-master-data-account-handler-panel.component';
import { BoltMasterDataProductionCompaniesComponent } from './components/production-companies/production-companies/bolt-master-data-production-companies.component';
import { BoltMasterDataProductionCompanyHandlerPanelComponent } from './components/production-companies/production-companies-handler-panel/production-companies-handler-panel.component';
import { BoltMasterDataProductionCompaniesAssociatedTitlesListComponent } from './components/production-companies/production-companies-associated-titles-list/bolt-master-data-production-companies-associated-titles-list.component';
import { BoltMasterDataDubbingStudioComponent } from './components/dubbing-studio/dubbing-studio.component';
import { BoltMasterDataDubbingStudioHandlerPanelComponent } from './components/dubbing-studio/dubbing-studio-panel.component';

@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    DropdownModule
  ],
  declarations: [
    BoltMasterDataLanguageComponent,
    BoltMasterDataLanguageHandlerPanelComponent,
    BoltMasterDataFiltersComponent,
    BoltMasterDataTerritoryComponent,
    BoltMasterDataAccountComponent,
    BoltMasterDataTerritoryHandlerPanelComponent,
    BoltMasterDataAccountHandlerPanelComponent,
    BoltMasterDataProductionCompaniesComponent,
    BoltMasterDataProductionCompanyHandlerPanelComponent,
    BoltMasterDataProductionCompaniesAssociatedTitlesListComponent,
    BoltMasterDataDubbingStudioComponent,
    BoltMasterDataDubbingStudioHandlerPanelComponent,
  ],
  exports: [ ],
  providers: [
    MasterDataService,
    MasterDataManager
  ]
})
export class MasterDataModule { }
