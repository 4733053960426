import { EventTypeEnum } from './event-type.enum';
import { JobStatusEnum } from './job-status.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';

export class JobStatusDetails {
  protected _uuid: string;
  protected _status: JobStatusEnum;
  protected _eventType: EventTypeEnum;
  protected _errorMessage?: string;

  constructor(data: any) {
    this.initialize(data);
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get status(): JobStatusEnum {
    return this._status;
  }

  set status(value: JobStatusEnum) {
    this._status = value;
  }

  get errorMessage(): string {
    return this._errorMessage;
  }

  set errorMessage(value: string) {
    this._errorMessage = value;
  }

  get eventType(): EventTypeEnum {
    return this._eventType;
  }

  set eventType(value: EventTypeEnum) {
    this._eventType = value;
  }

  /**
   * Indicates if it is IN_PROGRESS.
   *
   * @returns boolean
   */
  isInProgress(): boolean {
    const isIt: boolean = (
      this.status === JobStatusEnum.IN_PROGRESS
    );

    return isIt;
  }

  /**
   * Indicates if it is ERROR.
   *
   * @returns boolean
   */
  isError(): boolean {
    const isIt: boolean = (
      this.status === JobStatusEnum.ERROR
    );

    return isIt;
  }

  /**
   * Indicates if it is COMPLETED.
   *
   * @returns boolean
   */
  isCompleted(): boolean {
    const isIt: boolean = (
      this.status === JobStatusEnum.COMPLETED
    );

    return isIt;
  }

  /**
   * Updates the event status.
   *
   * @param eventStatus JobStatusEnum
   * @returns void
   */
  public updateEventStatus(eventStatus: JobStatusEnum): void {
    this._status = eventStatus;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    if (data && data.hasOwnProperty('uuid') && data.hasOwnProperty('status') && data.hasOwnProperty('eventType')) {
      this._uuid = data.uuid;
      this.updateEventStatus(data.status);
      this._eventType = (data.hasOwnProperty('eventType') ? data.eventType : undefined);
      this._errorMessage = (data.hasOwnProperty('errorMessage') ? data.errorMessage : undefined);
    } else {
      throw new ErrorHelper('Cannot get needed fields for a job status details.');
    }
  }
}

