import { Component, ViewEncapsulation, ViewContainerRef, HostListener, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { OutageNotificationConfig } from '@bolt/ui-shared/notification/';
import { isString as _isString } from 'lodash';

import { AuthHelper } from './modules/auth/helpers/auth/auth.helper';
import { UserManager } from './modules/user/helpers/user-manager.helper';
import { SnowplowTrackerService } from './modules/analytics/snowplow-tracker.service';
import { StormServiceResponseSingle } from './modules/common/services/storm-service-response-single';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';


@Component({
  selector: 'bolt-app',
  template: require('./bolt.html'),
  styles: [require('./bolt.scss')],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  viewContainerRef: ViewContainerRef;

  protected useFooter: boolean;
  protected useHeader: boolean;

  protected outageConfig: OutageNotificationConfig;

  constructor(
    viewContainerRef: ViewContainerRef,
    protected appConfig: AppConfigProvider,
    protected authHelper: AuthHelper,
    protected router: Router,
    protected userManager: UserManager,
    protected snowplowTrackerService: SnowplowTrackerService,
    protected titleService: Title,
    protected activatedRoute: ActivatedRoute
  ) {

    // You need this small hack in order to catch application root view container ref.
    this.viewContainerRef = viewContainerRef;

    this.setupOutageNotification();
    this.discoverIfUseFooter();
    this.discoverIfUseHeader();
    this.handlePageLoad();

    router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.userManager.fetchAuthenticatedUser().subscribe(
          (response: StormServiceResponseSingle) => {
            this.snowplowTrackerService.trackPageView(response.item);
          }
        );
      }
    });

  }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return 'BOLT - ' + child.snapshot.data['title'];
          }
          return appTitle;
        })
      ).subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
  }

  /**
   * Discovers if it has to display the footer.
   *
   * @returns void
   */
  protected discoverIfUseFooter(): void {
    this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationStart) {
          this.useFooter =
            !event.url.startsWith('/application') &&
            !event.url.includes('login');
        }
      }
    );
  }

  /**
   * Discovers if it has to display the header.
   *
   * @returns void
   */
  protected discoverIfUseHeader(): void {
    this.router.events.subscribe(
      (event: Event) => {
        if (event instanceof NavigationStart) {
          this.useHeader =
            !event.url.startsWith('/auth') ||
            event.url.includes('access-denied') ||
            event.url.includes('restricted-access');
        }
      }
    );
  }

  /**
   * Sets up the outage notification
   *
   * @returns void
   */
  protected setupOutageNotification(): void {
    const config = {
      duration: this.appConfig.get('outage.duration'),
      hidden: this.appConfig.get('outage.hidden'),
      message: this.appConfig.get('outage.message')
    };

    this.outageConfig = new OutageNotificationConfig(config.message, config.duration, config.hidden);
  }

  /**
   * Handles the page loading event for requesting the auth token.
   *
   * @returns void
   */
  @HostListener('window:load')
  protected handlePageLoad(): void {
    if (!sessionStorage.getItem(this.authHelper.tokenName)) {
      localStorage.setItem('bolt-token-share-request', '');
      localStorage.removeItem('bolt-token-share-request');
    }
  }

  /**
   * Handles the storage change events for handling the auth token.
   *
   * @param event any
   * @returns void
   */
  @HostListener('window:storage', ['$event'])
  protected handleStorageChanges(event: any): void {
    if (
      ((event.key === 'bolt-token-share-response') || (event.key === 'bolt-broadcast-authenticated')) &&
      _isString(event.newValue) &&
      (event.newValue.length > 0) &&
      (event.newValue !== this.authHelper.getToken())
    ) {
      console.log('Switching identity to', event.newValue);
      this.authHelper.storeToken({ access_token: event.newValue }, false);

      if (event.key === 'bolt-broadcast-authenticated') {
        window.location.reload();
      }
    }

    if ((event.key === 'bolt-broadcast-logout') && _isString(this.authHelper.getToken())) {
      console.log('Logout everywhere for', this.authHelper.getToken());
      this.authHelper.logout(false);
      this.router.navigate(['/auth/login']);
    }

    if ((event.key === 'bolt-token-share-request') && _isString(this.authHelper.getToken())) {
      console.log('Sharing the identity', this.authHelper.getToken());
      localStorage.setItem('bolt-token-share-response', this.authHelper.getToken());
      localStorage.removeItem('bolt-token-share-response');
    }
  }
}
