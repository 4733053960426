import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isEmpty as _isEmpty } from 'lodash';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { JobStatusDetails } from '../../models/job-status/job-status-details.model';


@Injectable({
  providedIn: 'root',
})
export class JobStatusService extends BoltAbstractService {

  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetches the cloning event status of the given id event
   *
   * @param eventId string
   * @returns Observable<JobStatusDetails>
   */
  public fetchJobEventStatus(eventId: string): Observable<JobStatusDetails> {
    const endpoint = 'event.fetchEventStatus.endpoint';
    const endpointParams: any = {
      '{eventId}': eventId
    };
    const url = this.generateUrl(endpoint, endpointParams);

    return this.authHttp.get(url).pipe(
      map(
        response => {
          const cloningStatus: JobStatusDetails = new JobStatusDetails(response);
          return cloningStatus;
        }
      ),
      catchError(
        error => {
          return this.defaultCatchOn(error);
        }
      )
    );
  }


  /**
   * Fetches the cloning event of the given id event
   *
   * @param eventId string
   * @returns Observable<JobStatusDetails>
   */
  public fetchJobEvent(eventId: string): Observable<JobStatusDetails> {
    const endpoint = 'event.fetchEvent.endpoint';
    const endpointParams: any = {
      '{eventId}': eventId
    };
    const url = this.generateUrl(endpoint, endpointParams);

    return this.authHttp.get(url).pipe(
      map(
        response => {
          const cloningStatus: JobStatusDetails = new JobStatusDetails(response);
          return cloningStatus;
        }
      ),
      catchError(
        error => {
          return this.defaultCatchOn(error);
        }
      )
    );
  }
}
