import { Injectable } from '@angular/core';
import { sortBy as _sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { Language } from '@bolt/ui-shared/master-data';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { StormServiceResponseSingle } from './storm-service-response-single';
import { CheckTypeEnum } from './check-type.enum';
import { BoltAbstractService } from './bolt-abstract.service';
import { StockType } from '../models/stock-type.model';


@Injectable({
  providedIn: 'root'
})
export class AutopopulateService extends BoltAbstractService {

  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Get the list of languages configured for autopopulate
   *
   * @returns Observable<Language[]>
   */
  getAutopopulateLanguages(): Observable<Language[]> {
    const queryParams = new UrlSearchParams();
    queryParams.set('autoPopulate', 'true');
    queryParams.set('_size', '1000');

    const url: string = this.generateUrl(
      'newMasterdata.fetch.language.autopopulate.endpoint',
      null,
      queryParams
    );

    const obs: Observable<Language[]> = this.doGetRequestAsObservable(
      { url: url, checkType: CheckTypeEnum.object }
    ).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          let languages: Language[] = new Array();

          languages = response.item.content.map(
            (item: any) => {
              const { id, name, alternateName, localeLanguage, languageType, iso6391 } = item;
              const language = new Language(id, name, alternateName, localeLanguage, languageType, iso6391);

              return language;
            }
          );

          return _sortBy(languages, [(lang: Language) => lang.name.toLowerCase()]);
        }
      )
    );

    return obs;
  }

  /**
   * Get the list of stock types for autopopulate
   *
   * @returns Observable<StockType[]>
   */
  getStockTypes(): Observable<StockType[]> {
    const url: string = this.generateUrl(
      'newMasterdata.fetch.stockType.endpoint'
    );

    const obs: Observable<StockType[]> = this.doGetRequestAsObservable(
      { url: url, checkType: CheckTypeEnum.array }
    ).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          let types: StockType[] = new Array();

          if (!response.isEmpty()) {
            types = response.item.map(
              (item: any) => {
                const stockType = new StockType(item);

                return stockType;
              }
            );

            return _sortBy(types, ['order']);
          }

            return types;
        }
      )
    );

    return obs;
  }

  /**
   * Populates the given titles and localization with the given stock types values
   *
   * @param data object
   * @returns Observable<StormServiceResponseSingle>
   */
  autopopulate(
    data: { titleIds: number[], locales: string[], autoPopulateType: number, titleType: string }
  ): Observable<StormServiceResponseSingle> {
    const url: string = this.generateUrl(
      'productService.autopopulate.endpoint'
    );
    const dataString = JSON.stringify(data);

    return this.doPostRequestAsObservable({
      url: url,
      body: dataString
    });
  }
}
