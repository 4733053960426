export class StockType {
  protected _name: string;
  protected _id: number;
  protected _order: number;
  protected _languageCodes: string[];

  constructor(data: any) {
    this._id = data.id;
    this._name = data.name;
    this._order = data.order;
    this._languageCodes = data.languageCodes;
  }

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    this._name = name;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get order(): number {
    return this._order;
  }

  public set order(order: number) {
    this._order = order;
  }

  public get languageCodes(): string[] {
    return this._languageCodes;
  }

  public set languageCodes(languageCodes: string[]) {
    this._languageCodes = languageCodes;
  }
}
