import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { isEmpty as _isEmpty } from 'lodash';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { Details } from '../../models/export/status/details/details.model';
import { Episode } from 'app/modules/title/models/episode.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Status } from '../../models/export/status/status.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Subtitle } from '../../models/subtitle.model';
import { SubtitleLockingStatus } from '../../models/subtitle-locking-status.model';
import { SubtitleTypeEnum } from '../../models/subtitle/details/subtitle-type/subtitle-type.enum';
import { Title, TitleType } from 'app/modules/title/models/title.model';


@Injectable({
  providedIn: 'root'
})
export class SubtitleService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetches the subtitle for given title.
   *
   * @param titleType TitleType
   * @param titleId number
   * @param subtitleType SubtitleTypeEnum
   * @returns Observable<Subtitle>
   */
  fetchSubtitle(titleType: TitleType, titleId: number, subtitleType: SubtitleTypeEnum = SubtitleTypeEnum.all): Observable<Subtitle> {
    const colonParams: any = {
      '{titleType}': titleType.toString(),
      '{titleId}': titleId
    };

    const queryParams: any = {
      type: subtitleType.toString()
    };

    const url: string = this.generateUrl('subtitle.subtitleService.fetch.endpoint', colonParams, queryParams);

    const obs: Observable<Subtitle> = this.doGetRequestAsObservable({ url: url }).pipe(
      map(
        (response: StormServiceResponseSingle) => new Subtitle(response.item)
      )
    );

    return obs;
  }

  /**
   * Returns an observable to request a file to be downloaded.
   *
   * @param title Title
   * @param subtitleType SubtitleTypeEnum
   * @param locale string
   * @returns Observable<any>
   */
  requestExportableFile(title: Title, subtitleType: SubtitleTypeEnum, locale?: string): Observable<any> {
    const endpoint = 'subtitle.subtitleService.requestExportableFile.endpoint';

    const endpointParams: any = {
      '{titleType}': title.type.toString().toLowerCase(),
      '{titleId}': title.id.toString(),
    };

    const queryParams: UrlSearchParams = new UrlSearchParams();
    queryParams.set('onScreenType', subtitleType.toString());

    locale = locale || '';

    if (!_isEmpty(locale)) {
      queryParams.set('localization', locale);
    }

    const obs: Observable<any> = this.authHttp.patch(this.generateUrl(endpoint, endpointParams, queryParams), undefined).pipe(
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      map(
        (response: HttpResponse<any>) => new Details(response)
      )
    );

    return obs;
  }

  /**
   * Returns an observable to request a file to be downloaded.
   *
   * @param title Title
   * @param subtitleType SubtitleTypeEnum
   * @param locale string
   * @returns Observable<any>
   */
  requestExportableBulkFile(title: Title, subtitleType: SubtitleTypeEnum, locale?: string): Observable<any> {
    const endpoint = 'subtitle.subtitleService.requestExportableBulkFile.endpoint';
    const episode = title as Episode;
    const queryParams: UrlSearchParams = new UrlSearchParams();

    const endpointParams = {
      '{seasonId}': episode.seasonId.toString()
    };

    locale = locale || '';
    queryParams.set('onScreenType', subtitleType.toString());

    if (!_isEmpty(locale)) {
      queryParams.set('localization', locale);
    }

    const obs: Observable<any> = this.authHttp.patch(this.generateUrl(endpoint, endpointParams, queryParams), undefined).pipe(
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      map(
        (response: HttpResponse<any>) => new Details(response)
      )
    );

    return obs;
  }

  /**
   * Returns an observable to ask the data of the file to be downloaded.
   *
   * @param exportedFileId string
   * @returns Observable<any>
   */
  fetchExportableFileData(exportedFileId: string): Observable<any> {
    const endpoint = 'subtitle.subtitleService.fetchExportableFileData.endpoint';

    const endpointParams: any = {
      '{exportId}': exportedFileId
    };

    const obs: Observable<any> = this.authHttp.get(this.generateUrl(endpoint, endpointParams)).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          return this.defaultCatchOn(error);
        }
      ),
      map(
        (response: HttpResponse<any>) => new Details(response)
      )
    );

    return obs;
  }

  /**
   * Returns an observable to ask the status of the file to be downloaded.
   *
   * @param exportedFileId string
   * @returns Observable<any>
   */
  fetchExportableFileStatus(exportedFileId: string): Observable<any> {
    const endpoint = 'subtitle.subtitleService.fetchExportableFileStatus.endpoint';

    const endpointParams: any = {
      '{exportId}': exportedFileId
    };

    const obs: Observable<any> = this.authHttp.get(this.generateUrl(endpoint, endpointParams)).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          return this.defaultCatchOn(error);
        }
      ),
      map(
        (response: HttpResponse<any>) => {
          const status: Status = new Status(response);
          return status;
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable to ask the status of the bulk file to be downloaded.
   *
   * @param exportedFileId string
   * @returns Observable<any>
   */
  fetchExportableBulkFileStatus(exportedFileId: string): Observable<any> {
    const endpoint = 'subtitle.subtitleService.fetchExportableBulkFileStatus.endpoint';

    const endpointParams: any = {
      '{exportId}': exportedFileId
    };

    const obs: Observable<any> = this.authHttp.get(this.generateUrl(endpoint, endpointParams)).pipe(
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      map(
        (response: HttpResponse<any>) => new Status(response)
      )
    );

    return obs;
  }

  /**
   * Given a file id, fetches the exported subtitles file
   *
   * @param exportedFileId string
   * @returns Observable<any>
   */
  fetchExportableFile(exportedFileId: string): Observable<any> {
    const endpoint = 'subtitle.subtitleService.fetchExportableFile.endpoint';

    const endpointParams: any = {
      '{exportId}': exportedFileId
    };

    const params: any = {
      responseType: 'blob'
    };

    const obs: Observable<any> = this.authHttp.get(this.generateUrl(endpoint, endpointParams), params).pipe(
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      map(
        (response: HttpResponse<any>) => {
          const generatedFile: any = response;

          // @todo Ensure this works.
          if (generatedFile instanceof Blob) {
            return generatedFile;
          } else {
            throw new ErrorHelper('Cannot get a file.');
          }
        }
      )
    );

    return obs;
  }

  /**
   * Given a file id, fetches the exported subtitles bulk file
   *
   * @param exportedFileId string
   * @returns Observable<any>
   */
  fetchExportableBulkFile(exportedFileId: string): Observable<any> {
    const endpoint = 'subtitle.subtitleService.fetchExportableBulkFile.endpoint';

    const endpointParams: any = {
      '{exportId}': exportedFileId
    };

    const params: any = {
      responseType: 'blob'
    };

    const obs: Observable<any> = this.authHttp.get(this.generateUrl(endpoint, endpointParams), params).pipe(
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      ),
      map(
        (response: HttpResponse<any>) => {
          const generatedFile: any = response;

          // @todo Ensure this works.
          if (generatedFile instanceof Blob) {
            return generatedFile;
          } else {
            throw new ErrorHelper('Cannot get a file.');
          }
        }
      )
    );

    return obs;
  }

  /**
   * Fetches the localized subtitle for given title and locale.
   *
   * @param titleType TitleType
   * @param titleId number
   * @param locale string
   * @param subtitleType SubtitleTypeEnum
   * @returns Promise<Subtitle>
   */
  fetchSubtitleLocalization(
    titleType: TitleType,
    titleId: number,
    locale: string,
    subtitleType: SubtitleTypeEnum = SubtitleTypeEnum.all
  ): Observable<Subtitle> {
    const endpoint = 'subtitle.subtitleService.fetchLocalization.endpoint';

    const endpointParams: any = {
      '{titleType}': titleType.toString().toLowerCase(),
      '{titleId}': titleId.toString(),
      '{locale}': locale
    };

    const queryParams: UrlSearchParams = new UrlSearchParams();
    queryParams.set('type', subtitleType.toString());

    const obs: Observable<Subtitle> = this.authHttp.get(this.generateUrl(endpoint, endpointParams, queryParams)).pipe(
      map(
        (response: HttpResponse<any>) => new Subtitle(response)
      )
    );

    return obs;
  }

  /**
   * Fetches the locking status for the given subtitle.
   *
   * @param titleType TitleType
   * @param titleId Number
   * @returns Observable<SubtitleLockingStatus>
   */
  fetchSubtitleLockingStatus(titleType: TitleType, titleId: number): Observable<SubtitleLockingStatus> {
    const params: any = {
      '{titleType}': titleType.toString().toLowerCase(),
      '{titleId}': titleId
    };

    const url: string = this.generateUrl('subtitle.subtitleService.fetchLockingStatus.endpoint', params);

    const obs: Observable<SubtitleLockingStatus> = this.doGetRequestAsObservable({ url: url }).pipe(
      map(
        (response: StormServiceResponseSingle) => new SubtitleLockingStatus(response.item)
      )
    );

    return obs;
  }

  /**
   * Fetches the locking status for the given localized subtitle.
   *
   * @param titleType TitleType
   * @param titleId Number
   * @param locale string
   * @returns Observable<SubtitleLockingStatus>
   */
  fetchSubtitleLocalizationLockingStatus(titleType: TitleType, titleId: number, locale: string): Observable<SubtitleLockingStatus> {
    const endpoint = 'subtitle.subtitleService.fetchLocalizationLockingStatus.endpoint';

    const endpointParams: any = {
      '{titleType}': titleType.toString().toLowerCase(),
      '{titleId}': titleId.toString(),
      '{locale}': locale.toString(),
    };

    const obs: Observable<SubtitleLockingStatus> = this.authHttp.get(this.generateUrl(endpoint, endpointParams)).pipe(
      map(
        (response: HttpResponse<any>) => new SubtitleLockingStatus(response)
      )
    );

    return obs;
  }

  /**
   * Saves the given subtitle.
   *
   * @param subtitle Subtitle
   * @returns Promise<Subtitle>
   */
  saveSubtitle(subtitle: Subtitle): Promise<Subtitle> {
    const endpoint = 'subtitle.subtitleService.update.endpoint';

    const endpointParams: any = {
      '{titleType}': subtitle.titleType.toString().toLowerCase(),
      '{titleId}': subtitle.titleId.toString(),
    };

    const prom: Promise<Subtitle> = this.authHttp.post(
      this.generateUrl(endpoint, endpointParams),
      JSON.stringify(subtitle.asEndpointData())
    ).pipe(
      map(
        (response: HttpResponse<any>) => new Subtitle(response)
      )
    ).toPromise();

    return prom;
  }

  /**
   * Saves the given localized subtitle.
   *
   * @param subtitle Subtitle
   * @returns Promise<Subtitle>
   */
  saveSubtitleLocalization(subtitle: Subtitle): Promise<Subtitle> {
    const endpoint = 'subtitle.subtitleService.updateLocalization.endpoint';

    const endpointParams: any = {
      '{titleType}': subtitle.titleType.toString().toLowerCase(),
      '{titleId}': subtitle.titleId.toString(),
      '{locale}': subtitle.locale.toString(),
    };

    const prom: Promise<Subtitle> = this.authHttp.post(
      this.generateUrl(endpoint, endpointParams),
      JSON.stringify(subtitle.asEndpointData())
    ).pipe(
      map(
        (response: HttpResponse<any>) => new Subtitle(response)
      )
    ).toPromise();

    return prom;
  }

  /**
   * Updates the locking status for the given subtitle.
   *
   * @param subtitle Subtitle
   * @param doLock boolean
   * @returns Promise<SubtitleLockingStatus>
   */
  updateSubtitleLockingStatusLockUnlock(subtitle: Subtitle, doLock: boolean): Promise<SubtitleLockingStatus> {
    const endpoint: string = doLock
      ? 'subtitle.subtitleService.updateLockingStatusLock.endpoint'
      : 'subtitle.subtitleService.updateLockingStatusUnlock.endpoint';

    const endpointParams: any = {
      '{titleType}': subtitle.titleType.toString().toLowerCase(),
      '{titleId}': subtitle.titleId.toString(),
    };

    const prom: Promise<SubtitleLockingStatus> = this.authHttp.put(this.generateUrl(endpoint, endpointParams), '').pipe(
      map(
        (response: HttpResponse<any>) => new SubtitleLockingStatus(response)
      )
    ).toPromise();

    return prom;
  }

  /**
   * Updates the locking status for the given localized subtitle.
   *
   * @param subtitle Subtitle
   * @param doLock boolean
   * @returns Promise<SubtitleLockingStatus>
   */
  updateSubtitleLocalizationLockingStatusLockUnlock(subtitle: Subtitle, doLock: boolean): Promise<SubtitleLockingStatus> {
    const endpoint: string = doLock
      ? 'subtitle.subtitleService.updateLocalizationLockingStatusLock.endpoint'
      : 'subtitle.subtitleService.updateLocalizationLockingStatusUnlock.endpoint';

    const endpointParams: any = {
      '{titleType}': subtitle.titleType.toString().toLowerCase(),
      '{titleId}': subtitle.titleId.toString(),
      '{locale}': subtitle.locale.toString(),
    };

    const prom: Promise<SubtitleLockingStatus> = this.authHttp.put(this.generateUrl(endpoint, endpointParams), '').pipe(
      map(
        (response: HttpResponse<any>) => new SubtitleLockingStatus(response)
      )
    ).toPromise();

    return prom;
  }

  /**
   * Updates the locking status for the given localized subtitle.
   *
   * @param subtitle Subtitle
   * @param doLock boolean
   * @returns Promise<SubtitleLockingStatus>
   */
  updateLocalizationLockingStatusLockUnlock(subtitle: Subtitle, doLock: boolean): Promise<SubtitleLockingStatus> {
    const endpoint: string = doLock
      ? 'subtitle.subtitleService.updateLocalizationLockingStatusLock.endpoint'
      : 'subtitle.subtitleService.updateLocalizationLockingStatusUnlock.endpoint';

    const endpointParams: any = {
      '{titleType}': subtitle.titleType.toString().toLowerCase(),
      '{titleId}': subtitle.titleId.toString(),
      '{locale}': subtitle.locale.toString(),
    };

    const prom: Promise<SubtitleLockingStatus> = this.authHttp.put(this.generateUrl(endpoint, endpointParams), '').pipe(
      map(
        (response: HttpResponse<any>) => new SubtitleLockingStatus(response)
      )
    ).toPromise();

    return prom;
  }
}
