import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Company, MasterDataCacheService, TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { MasterDataService } from 'app/modules/masterData/services/master-data.service';
import { BoltBaseMasterDataComponent } from '../../baseMasterData/main-page/baseMasterData.component';

@Component({
  selector: 'bolt-master-data-production-companies',
  template: require('./bolt-master-data-production-companies.html'),
  styles: [ require('../../baseMasterData/main-page/baseMasterData.scss') ]
})
export class BoltMasterDataProductionCompaniesComponent extends BoltBaseMasterDataComponent implements OnInit, OnDestroy {
  protected viewTitlesModal: NgbModalRef;
  protected selectedCompany: Company;

  constructor(
    protected appConfig: AppConfigProvider,
    protected masterDataService: MasterDataService,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected cacheService: MasterDataCacheService
  ) {
    super(appConfig, masterDataService, notificationService, cacheService);
    this.hasOpenHandlerPanel = false;
    this.searchedWords = '';
    this.type = MasterDataType.productionCompany;
    this.setupPager();
  }

  ngOnInit(): void {
    this.fetch();
  }

  ngOnDestroy(): void {
    if (this.viewTitlesModal) {
      this.viewTitlesModal.close();
    }
  }

  openViewTitlesModal(content: any, company: Company): void {
    this.viewTitlesModal = this.modalService.open(content);
    this.selectedCompany = company;
  }
}
