import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Country, Language, ProductType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isObject as _isObject } from 'lodash';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { LocaleForm } from '../../models/locale/form/locale-form.model';
import { Title } from 'app/modules/title/models/title.model';
import { AutopopulateService } from '../../services/autopopulate.service';
import { StockType } from '../../models/stock-type.model';
import { Episode } from 'app/modules/title/models/episode.model';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { StormServiceResponseSingle } from '../../services/storm-service-response-single';
import { JobStatusDetails } from '../../models/job-status/job-status-details.model';


@Component({
  selector: 'bolt-autopopulate-metadata',
  template: require('./bolt-autopopulate-metadata.html'),
  styles: [require('./bolt-autopopulate-metadata.scss')],
})
export class BoltAutopopulateMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() show: boolean;
  @Input() title: Title;
  @Output() autopopulateEvent: EventEmitter<JobStatusDetails>;
  @Output() closeEvent: EventEmitter<any>;
  @ViewChild('autopopulateModalRef') modal: ModalDirective;
  @ViewChild('confirmAutopopulateRef') confirmModal: NgbModalRef;

  autopopulateForm: FormGroup;
  stockTypeOptions: SelectionItem[];
  selectedEpisodes: Episode[];
  hasShowEpisodes: boolean;
  isSaving: boolean;
  isDoubleChecked: boolean;
  subscriptions: Subscription;
  hasShowDoubleCheck: boolean;
  hasDisplayProcessingSpinner: boolean;

  constructor(
    protected formBuilder: FormBuilder,
    protected autopopulateService: AutopopulateService,
    protected listLayoutProvider: ListLayoutProvider,
    protected modalService: NgbModal,
    protected notificationService: NotificationService
  ) {
    this.closeEvent = new EventEmitter<any>();
    this.autopopulateEvent = new EventEmitter<any>();
  }

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show)) {
      if (changes.show.currentValue) {
        this.open();
        this.initialize();
      }
    }
  }

  ngOnDestroy() {
    this.autopopulateForm.reset();
    this.subscriptions.unsubscribe();
  }

  getLocaleForm(): LocaleForm {
    return this.autopopulateForm.get('localeForm') as LocaleForm;
  }

  hasBlock(): boolean {
    return this.autopopulateForm.invalid || this.isSaving;
  }

  isFeature(): boolean {
    return this.title.isFeature();
  }

  isEpisode(): boolean {
    return this.title.isEpisode();
  }

  toggleShowEpisodes(): void {
    this.hasShowEpisodes = !this.hasShowEpisodes;
  }

  toggleDoubleChecked(): void {
    this.isDoubleChecked = !this.isDoubleChecked;
  }

  getLocalizations(): any {
    return this.getLocaleForm().getLanguageField().value;
  }

  getStockType(): string {
    const selectedStockType = this.autopopulateForm.get('stockType').value;
    const stockTypeName = this.stockTypeOptions.find(
      (item) => item.value === selectedStockType
    ).label;

    return stockTypeName;
  }

  autopopulate(): void {
    this.isSaving = true;

    const data = {
      titleIds: this.getTitles(),
      locales: this.getLocales(),
      autoPopulateType: this.autopopulateForm.get('stockType').value,
      titleType: this.title.type.toString().toLowerCase()
    };

    const subs: Subscription = this.autopopulateService.autopopulate(data).subscribe(
      (response: StormServiceResponseSingle) => {
        const jobDetail = new JobStatusDetails(response.item);
        this.autopopulateEvent.emit(jobDetail);
        this.isSaving = false;
        this.autopopulateForm.reset();
        this.modalService.dismissAll();
        this.modal.hide();
      },
      (error) => {
        this.notificationService.handleError('There was an error trying to auto-populate this title.', error);
        this.isSaving = false;
        this.modalService.dismissAll();
        this.close();
      }
    );

    this.subscriptions.add(subs);
  }

  close(): void {
    this.closeEvent.emit('');
    this.modal.hide();
    this.autopopulateForm.reset();
  }

  /**
   * Opens the confirmation dialog.
   *
   * @returns void
   */
  showConfirmationDialog(): void {
    if (this.getLocalesSize() > 5500) {
      this.notificationService.handleWarning('The current locales selection exceeds the 5500. Please select a downsize your selection and try again.');
    } else {
      this.isDoubleChecked = false;
      this.hasShowDoubleCheck = false;
      this.modalService.open(this.confirmModal);

      setTimeout(
        () => {
          this.hasShowDoubleCheck = true;
        },
        0
      );
    }
  }

  /**
   * Updates the episodes selected with the emitted value
   * @param episodes Episode[]
   * @returns void
   */
  setSelection(episodes: Episode[]): void {
    this.selectedEpisodes = episodes;
  }

  /**
   * Opens this modal.
   *
   * @returns void
   */
  open(): void {
    this.modal.show();
  }

  getLocalesSize(): number {
    const { territory, productType, account } = this.getLocaleForm().toJson();
    const selectedLanguages = this.getSelectedLanguages();

    const size = selectedLanguages.length * territory.length * productType.length * account.length;

    return size;
  }

  getSelectedLanguages(): Language[] {
    const { language } = this.getLocaleForm().toJson();
    const selectedType: number = this.autopopulateForm.get('stockType').value;

    const autoPopulateType: StockType = this.stockTypeOptions.find(
      (stockType: SelectionItem) => stockType.value === selectedType
    ).source;

    const filteredLanguages: Language[] = autoPopulateType.languageCodes.map(
      (locale: string) => {
        return this.listLayoutProvider.getLanguageByLocale(locale);
      }
    );

    const selectedLanguages = filteredLanguages.filter(
      (filteredLanguage: Language) => (language as number[]).includes(filteredLanguage.id)
    );

    return selectedLanguages;
  }

  /**
   * Returns a list of the locales selected
   *
   * @returns string[]
   */
  protected getLocales(): string[] {
    const { territory, productType, account } = this.getLocaleForm().toJson();
    const locales: string[] = [];
    const selectedLanguages = this.getSelectedLanguages();

    selectedLanguages.forEach(
      (selectedLanguage: Language) => {
        territory.forEach(
          (territoryId: number) => {
            productType.forEach(
              (productTypeId: number) => {
                account.forEach(
                  (accountId: number) => {
                    const territoryCode = this.listLayoutProvider.getTerritoryById(territoryId).code;
                    const productTypeCode = this.listLayoutProvider.getProductTypeById(productTypeId).code;
                    const accountCode = this.listLayoutProvider.getAccountById(accountId).code;

                    locales.push(
                      selectedLanguage.code + '_' + territoryCode + '_' + productTypeCode + '_' + accountCode
                    );
                  }
                );
              }
            );
          }
        );
      }
    );

    return locales;
  }

  /**
   * Get the titles to be auto-populated
   *
   * @returns number[]
   */
  protected getTitles(): number[] {
    let titles = [this.title.id];

    if (this.title.isEpisode()) {
      const episodesId = this.selectedEpisodes?.map(
        (episode: Episode) => episode.id
      );

      titles = titles.concat(episodesId);
    }

    return titles;
  }

  protected initialize(): void {
    this.hasShowEpisodes = false;
    this.selectedEpisodes = [];
    this.stockTypeOptions = [];
    this.subscriptions = new Subscription();

    this.autopopulateForm = this.formBuilder.group({
      localeForm: new LocaleForm(),
      stockType: [null, Validators.required],
    });

    (this.autopopulateForm.get('localeForm') as LocaleForm).getTerritoryField().setValue([Country.ALL_ID]);
    (this.autopopulateForm.get('localeForm') as LocaleForm).getProductTypeField().setValue([ProductType.ALL_ID]);

    const subs: Subscription =  this.autopopulateService.getStockTypes().subscribe(
      (types: StockType[]) => {
        this.stockTypeOptions = types.map(
          (type: StockType) => new SelectionItem(type.name, type.id, type)
        );
      }
    );

    this.subscriptions.add(subs);
  }
}
