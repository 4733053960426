import { Component, Input, OnInit } from '@angular/core';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Company } from '@bolt/ui-shared/master-data';
import { TitleService } from 'app/modules/title/services/title.service';
import { SearchCriteria } from '@bolt/ui-shared/searching';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';

@Component({
  selector: 'bolt-master-data-production-companies-associated-titles-list',
  template: require('./bolt-master-data-production-companies-associated-titles-list.html'),
  styles: [require('./bolt-master-data-production-companies-associated-titles-list.scss')]
})

export class BoltMasterDataProductionCompaniesAssociatedTitlesListComponent extends StormComponent implements OnInit {
  @Input() productionCompany: Company;
  protected searchCriteria: SearchCriteria;
  protected searchResponse: SearchResponse;

  constructor(
    protected titleService: TitleService
  ) {
    super();
    this.searchCriteria = new SearchCriteria();
    this.searchCriteria.setPageSize(15);
  }

  ngOnInit() {
    this.fetchAssociatedTitles(this.productionCompany.id);
  }

  /**
   * Fetches all titles associated to the given production company id
   * @param id number
   * @returns void
   */
  protected fetchAssociatedTitles(id: number): void {
    this.changeStatusToFetchingData();

    this.titleService.findTitlesByProductionCompanyIds(this.searchCriteria, [id]).subscribe(
      response => {
        this.searchResponse = response;
        this.changeStatusToDataFound();
      },
      (error) => {
        this.changeStatusToError();
      }
    );
  }

  /**
   * Loads the given page.
   *
   * @param page number
   * @returns void
   */
  protected loadPage(page: number): void {
    this.searchCriteria.setPageNumber(page - 1);
    this.fetchAssociatedTitles(this.productionCompany.id);
  }
}
